import React from "react";

import { graphql, Link } from "gatsby";
import Img from "gatsby-image";
import { get } from "lodash";
// import contentParser from "gatsby-source-wpgraphql-images";
import contentParser from "gatsby-wpgraphql-inline-images";
import moment from "moment";
import "moment/locale/pt";

import Layout from "../components/layout";
import Sidebar from "../components/Sidebar";
import SEO from "../components/MetaSeo";
import Share from "../components/Share";
import Card from "../components/Card";
import { CalendarIcon } from "../components/Icons";
import Youtube from "../components/Youtube";

const FeaturedMedia = ({media, alt}) => (
  <div className="p-4 md:w-1/2 relative mx-auto">
    <div className="h-full border-2 border-black overflow-hidden">
      {/* <img className="lg:h-48 md:h-36 w-full object-cover object-center" src="https://dummyimage.com/720x400" alt="blog" /> */}
      {media.localFile && (
        <Img
          className="w-full object-cover object-center"
          alt={alt}
          fluid={media.localFile.childImageSharp.fluid}
        />
      )} 

      {/* <div className="p-6">
        <h2 className="tracking-widest text-xs title-font font-medium text-gray-500 mb-1">CATEGORY</h2>
        <h1 className="title-font text-lg font-medium text-gray-900 mb-3">The Catalyzer</h1>
        <p className="leading-relaxed mb-3">Photo booth fam kinfolk cold-pressed sriracha leggings jianbing microdosing tousled waistcoat.</p>
      </div> */}
    </div>
    </div>
)

const Hero = ({ title, featured_media, slug, categories, author, date }) => (
  <div className="block md:flex md:space-x-2 bg-black-dark sm:p-10"    style={{ marginBottom: "-10%" }}>
    <Link
      to={slug}
      className="text-center w-full relative inline-block"
   
    >
      {/* <div
        className="absolute left-0 bottom-0 w-full h-full z-10"
        style={{
          backgroundImage: "linear-gradient(180deg,transparent,rgba(0,0,0,.7))",
        }}
      ></div> */}

      {/* {featured_media.localFile && (
        <Img
          className="absolute left-0 top-0 w-full h-full z-0 object-cover"
          alt={slug}
          fluid={featured_media.localFile.childImageSharp.fluid}
        />
      )} */}

      <div className="container z-20 w-full text-center sm:p-40">
        {categories &&
          categories.map((category) => (
            <span
              key={category.slug}
              className="uppercase inline-block py-1 px-3 my-4 bg-red-dark text-white text-sm font-medium tracking-widest"
            >
              {category.name}
            </span>
          ))}

        <h1
          className="text-4xl md:text-6xl font-family-louize text-white text-center"
          dangerouslySetInnerHTML={{ __html: title }}
        />

        <div className="text-center text-black flex justify-center my-4">
            <div className="flex mt-3">
              <img
                src={author.avatar.url}
                className="h-10 w-10 rounded-full mr-2 object-cover"
              />
              <div>
                <p className="font-semibold text-white text-sm">
                  {author.name}{" "}
                </p>
                <p className="font-semibold text-red-dark text-xs">
                  <CalendarIcon />{" "}
                  {moment(Date.parse(date)).locale("pt").fromNow()}{" "}
                </p>
              </div>
            </div>
          </div>
      </div>
    </Link>
  </div>
);

const Title = ({ children }) => (
  <div className="font-family-louize text-cream line-through inline-block py-1 px-3 my-4 font-medium sm:text-5xl text-1xl font-black my-4">
    {children}
  </div>
);

const RelatedPost = ({ posts }) => (
  <>
    <Title>Relacionados</Title>
    <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3">
      {posts &&
        posts.map((node) => (
          <Card key={node.slug} {...node} className="w-full" fluid />
        ))}
    </div>
  </>
);

const Post = ({
  data: {
    wordpressPost: {
      slug,
      title,
      featured_media,
      categories,
      date,
      modified,
      excerpt,
      tags,
    },
    blog: {
      postBy: { 
        author,
        content,
        featuredVideo
      },
    },
    site: {
      siteMetadata: { siteUrl },
    },
    allWordpressPost,
  },
}) => {
  const wordPressUrl = process.env.GATSBY_WP_URL;
  const uploadsUrl = `${process.env.GATSBY_WP_URL}/wp-content/uploads/`;

  return (
    <Layout>
      <SEO
        title={title}
        description={excerpt}
        image={get(featured_media, "source_url", null)}
        pathname={slug}
        article={{
          dateModified: modified,
          datePublished: date,
          author: author,
        }}
      />
      <article>
        <Hero
          {...{
            slug,
            title,
            featured_media,
            categories,
            author,
            date,
            modified,
            excerpt,
          }}
        />

        <FeaturedMedia media={featured_media} />

        <section className="body-font post-header">
          <div className="container mx-auto flex px-5 py-0  items-center justify-center flex-col">
            <div className="text-center lg:w-2/3 w-full">
             

              <div className="text-center flex justify-center my-4">
                <Share
                  url={`${siteUrl}/${slug}`}
                  title={title}
                  media={get(featured_media, "source_url", null)}
                />
              </div>
              <div className="text-center flex justify-center my-4">
                <a
                  href="https://alertapromo.com/promocoes/exclusivas?utm_source=profregadas&utm_content=rect_ad"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={`/banner-728-90-exclusivas.png`}
                    alt="alertapromo.com"
                  />
                </a>
              </div>
            </div>
          </div>
        </section>

        <div className="container mx-auto">
          <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-4">
            <section className="col-span-4 p-2 lg:p-20 rounded">
              {featuredVideo.featuredvideo && (<Youtube title={title} description={excerpt} url={featuredVideo.featuredvideo} date={date} />)}
             
              <div className="entry-content text-lg">
                {contentParser({ content }, { wordPressUrl, uploadsUrl })}
              </div>
            </section>
          </div>
        </div>
      </article>

     

      <div className="container mx-auto">
        <Sidebar tags={tags}/>
      </div>

      <div className="bg-red-dark">
        <div className="container mx-auto">
          <RelatedPost posts={allWordpressPost.nodes} />
        </div>
      </div>
    </Layout>
  );
};

export default Post;

export const postQuery = graphql`
  query post($slug: String!, $categories: [String]) {
    wordpressPost(slug: { eq: $slug }) {
      date
      modified
      title
      slug
      excerpt
      tags {
        name
        slug
        path
      }
      categories {
        name
        slug
        path
      }
      featured_media {
        source_url
        localFile {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1800) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }

    blog {
      postBy(slug: $slug) {
        uri
        content
        featuredVideo {
          featuredvideo
        }
        author {
          name
          slug
          id
          description
          avatar {
            url
          }
        }
      }
    }

    site {
      siteMetadata {
        siteUrl
      }
    }

    allWordpressPost(
      filter: { categories: { elemMatch: { slug: { in: $categories } } } }
      limit: 3
    ) {
      nodes {
        title
        slug
        featured_media {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1800) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
  }
`;
