import React from "react";

import {
  FacebookShareButton,
  TwitterShareButton,
  PinterestShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  PinterestIcon,
  TelegramIcon,
  WhatsappIcon,
  EmailIcon,
} from "react-share";

const Share = ({ url, title, media }) => {
  return (
    <div>
      <FacebookShareButton url={url} quote={title} className="m-2">
        <FacebookIcon size={32} round />
      </FacebookShareButton>

      <TwitterShareButton url={url} title={title} className="m-2">
        <TwitterIcon size={32} round />
      </TwitterShareButton>

      <TelegramShareButton url={url} title={title} className="m-2">
        <TelegramIcon size={32} round />
      </TelegramShareButton>

      <WhatsappShareButton
        url={url}
        title={title}
        separator=":: "
        className="m-2"
      >
        <WhatsappIcon size={32} round />
      </WhatsappShareButton>

      <PinterestShareButton url={url} className="m-2" media={media}>
        <PinterestIcon size={32} round />
      </PinterestShareButton>

      <EmailShareButton url={url} subject={title} body="body" className="m-2">
        <EmailIcon size={32} round />
      </EmailShareButton>
    </div>
  );
};

export default Share;
