import React from "react";
import { LazyLoadComponent } from "react-lazy-load-image-component";

const Youtube = ({ title, description, url, date, width = "100%", height = "515" }) => {

    const videoURL = new URL(url)
    const videoId = videoURL.searchParams.get('v')
    
    const schema = [
            {
                "@context": "http://schema.org",
                "@type": "VideoObject",
                name: title,
                description: description,
                contentUrl: url,
                uploadDate: date,
                thumbnailUrl: `https://i.ytimg.com/vi/${videoId}/hqdefault.jpg`
            },
        ]

  return (
      <>
        <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
            __html: JSON.stringify(schema),
            }}
        />
        <LazyLoadComponent>
        <iframe
            width={width}
            height={height}
            src={`https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1`}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
        ></iframe>
        </LazyLoadComponent>
    </>
  );
};

export default Youtube;


